import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/:title,:id,:postid,:contactid?",
        name: "Run Survey",
        props: true,
        component: () =>
            import(/* webpackChunkName: "RunSurvey" */ "../views/RunSurvey"),
        meta: { hideNavigation: true },
    },
];


const router = new VueRouter({
    mode: "history",
    // eslint-disable-next-line no-undef
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const loggedIn = !!localStorage.getItem("user");
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!loggedIn) {
            Vue.$toast.error(`You must be logged in to access this menu item !`, {
                timeout: 2000,
            });
            next("/Login");
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;