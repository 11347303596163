import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import vuetify from './plugins/vuetify'
import axios from "axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;
const options = {
  // You can set your default toast options here
  timeout: 2000,
  position: "bottom-center",
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

Vue.use(Toast, options);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  axios,
  router,
  render: h => h(App)
}).$mount('#app')
