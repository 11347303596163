<template>
<v-container>
  <router-view></router-view>
</v-container>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
  created() {}
};
</script>
